import React from 'react';
import './Spinner.css'; // Ensure this file contains the CSS above

const Spinner = () => (
    <div className="overlay">
    <div className="spinner"></div>
  </div>
);

export default Spinner;
