import Avatar from 'react-avatar';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';// Import the back arrow icon from react-icons



function CustomerIndividualPage() {
  const { id } = useParams();
  const [clientData, setClientData] = useState([]);

  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clientdatas/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      });
      setClientData(response.data.clientdata);
      console.log(response.data.clientdata);
    } catch (error) {
      console.error('Error fetching individual client data:', error);
      // Handle errors, e.g., set an error state or display an error message
    }
  };

  useEffect(() => {
    if (id) {
      fetchClientData();
    }
  }, [id]);

  const handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };


  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col lg={8}>
          <Button
            variant="light"
            className="mb-3"
            onClick={handleGoBack}
            style={{
              backgroundColor: '#3498db', // Set background color to a shade of blue
              color: '#fff', // Set text color to white
              fontWeight: 'bold', // Make the text bold
              transition: 'background-color 0.3s ease', // Add a smooth transition effect for the background color
            }}
          >
            <FaArrowLeft style={{ marginRight: '5px' }} /> Back
          </Button>
          <div className="bg-white p-4 rounded shadow">
            <div className="text-center mb-4">
              {clientData?.Image ? (
                <img
                  src={clientData.Image}
                  alt="Profile"
                  className="rounded-circle border p-1"
                  style={{ width: '150px', height: '150px' }}
                />
              ) : (
                <Avatar name={clientData.name} size="150" round={true} />
              )}
            </div>

            <h2 className="text-center text-primary mb-4">Customer Detail</h2>

            <div>
              <Row>
                <Col md={6}>
                  <Category title="Basic Information">
                    <DataItem label="Name" value={clientData.Name} />
                    <DataItem label="District" value={clientData.District} />
                    <DataItem label="Phone" value={clientData.Phone} />
                    <DataItem label="ApplyType" value={clientData.ApplyType} />
                  </Category>
                </Col>

                <Col md={6}>
                  <Category title="Employment Information">
                    <DataItem label="Employee Code" value={clientData.EmployeeCode || 'N/A'} />
                    <DataItem label="Proof" value={clientData.Proof} />
                    <DataItem label="Proof Id" value={clientData.ProofId} />
                    <DataItem label="Referal Code" value={clientData.ReferalCode} />
                  </Category>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Category title="Property Information">
                    <DataItem label="Willing for Construct Building" value={clientData.ConstructBuilding ? 'Yes' : 'No'} />
                    <DataItem label="Having a Land Property" value={clientData.LandProperty ? 'Yes' : 'No'} />
                  </Category>
                </Col>

                <Col md={6}>
                  <Category title="Purchase Information">
                    <DataItem label="Purchase Quantity" value={clientData.Quantity} />
                    <DataItem label="Total Amount" value={clientData.TotalAmount} />
                  </Category>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Category title="Payment and Approval">
                    <DataItem label="Approval Status" value={clientData.ApprovalStatus} />
                    <DataItem label="Issued Status" value={clientData.IssuedStatus ? 'Yes' : 'No'} />
                    <DataItem label="Payment Status" value={clientData.PaymentStatus} />
                  </Category>
                </Col>
                <Col md={6} className="mt-5">
                <DataItem label="Payment ReferalCode" value={clientData.ReferalCode} />
                <DataItem label="Payment ReferenceNumber" value={clientData.Reference_no} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
function Category({ title, children }) {
  return (
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ color: '#3498db', fontSize: '1.5em', marginBottom: '10px' }}>{title}</h3>
      {children}
    </div>
  );
}

function DataItem({ label, value }) {
  return (
    <div
      style={{
        marginBottom: '15px',
        padding: '10px',
        background: '#f9f9f9',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <strong
        style={{
          color: '#555',
          textTransform: 'capitalize',
          flex: '1',
        }}
      >
        {label}
      </strong>
      <span style={{ color: '#333', flex: '2', textAlign: 'right' }}>{value}</span>
    </div>
  );
}

export default CustomerIndividualPage;

