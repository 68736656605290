import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import NavBar from './Components/Navbar/NavBar';
import Footer from './Components/Footer';
import CustomerIndividualPage from './Pages/CustomerIndividualPage';
import AdminLoginPage from './Pages/AdminLoginPage/AdminLoginPage';
import AdminHomePage from './Pages/AdminHomePage';
import AdminAddEmployeePage from './Pages/AdminAddEmployeePage/AdminAddEmployeePage';

import AgentRegister from './Pages/Agent';
import PurchaseDetails from './Pages/PurchaseDetails/PurchaseDetails';
import AdminEmployeeTable from './Pages/AdminEmployeeTable';
import EmployeeIndividualPage from './Pages/EmployeeIndividualPage';
import LuckyDrawApp from './Pages/LuckyDraw/LuckyDraw';
import AdminRegistrationPage from './Pages/AdminRegistrationPage';
import CustomerRegistrationPage from './Pages/CustomerRegistrationPage';
import SuccessScreen from './Pages/SuccessScreen';
import CancelScreen from './Pages/CancelScreen';
import ReturnPolicy from './Components/ReturnPolicy';
import TermsCondition from './Components/TermsCondition';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Replace with your actual JWT token key
    setIsAuthenticated(!!token);
  }, []);

  return (
    <>
      <BrowserRouter>
        {isAuthenticated && <NavBar />}
        <Routes>
          <Route
            path="/vibadminlogin"
            element={isAuthenticated ? <Navigate to="/adminhomepage" /> : <AdminLoginPage />}
          />
          <Route path="/" element={<>
          <CustomerRegistrationPage/>
          <Footer/></>} />
          {isAuthenticated && (
            <>
              <Route path="/adminhomepage" element={<AdminHomePage />} />
              {/* <Route path="/adminregistrationpage" element={<AdminRegistrationPage />} /> */}
              <Route path="/AgentRegister" element={<AgentRegister />} />
              <Route path="/purchasedetailsvib" element={<PurchaseDetails />} />
              <Route path="/luckydraw" element={<LuckyDrawApp />} />
              <Route path="/customerindividualpage/:id" element={<CustomerIndividualPage />} />
              <Route path="/employeeindividualpage/:id" element={<EmployeeIndividualPage />} />
              <Route path="/adminaddemployeepage" element={<AdminAddEmployeePage />} />
              <Route path="/adminemployeetable" element={<AdminEmployeeTable/>} />
            </>
          )}
          <Route path='/payment' element={<><SuccessScreen/><Footer/></>}/>
          <Route path='/Cancel' element={<><CancelScreen/><Footer/></>}/>
          <Route path='/returnpolicy' element={<><ReturnPolicy/><Footer/></>}/>
          <Route path='/terms&condition' element={<><TermsCondition/><Footer/></>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
