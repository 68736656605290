import React from 'react'
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png"
import { Link } from 'react-router-dom'

const TermsCondition = () => {
  return (
    <div className='container'>
        <div className='d-flex justify-content-center mt-2'>
        <Link to='/'><img src={logo} alt='logo' style={{ width: "234px", height: "130px" }}/></Link>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-2" style={{ height: '50px'}}>
  <img src={GSTN_logo} alt="GST" style={{ width: "40px", height: "40px" }} />
  <p style={{ margin: 0, lineHeight: '40px',fontSize:'15px' }}>GSTIN : 33AKTPV6692C1ZJ</p>
</div>
        <div style={{display:'flex',flexDirection:'column',gap:'10px',margin:'20px'}}>
      <h2 className='text-center'>Terms of Service</h2>
      <h3>INTER-LOCK STONE</h3>
    <ol style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        <li>Please ensure to check the quantity of the stone once you receive it.</li>
        <li>The image showing the product/stone is closely identical except for invisible or minor/macro level variations thereof.</li>
        <li>Please ensure the quantity numbers and also ensure to revert within 48 hours upon receipt on delivery of the product/stone.</li>
        <li>The product’s transit/shipping/delivery charges are determined and listed on the website by the sellers/manufacturers.</li>
        <li>The product/stone is based purely on advance payment through our website, and the delivery would be made as determined and updated on the website of the seller or manufacturer.</li>
        <li>The quality specification of the product for the intended utilization is the same as the specification demonstrated and illustrated on our website.</li>
        <li>Misuse of the product/stone or damage out of negligence shall not create any liability on us.</li>
        <li>The amount received towards the purchase of the product/stone shall not be refunded by us for any reasons out of negligence/misuse/mishandling.</li>
        <li>The buyer shall ensure to take a short video snap of the product once the defect is identified while opening the delivery package/box.</li>
        <li>The product is meant for utilization as per the quality specification, and it is highly durable and lasts longer if utilized in the manner set out by us.</li>
        <li>Any damage to the product due to force majeure shall not create any liability on the seller/manufacturer.</li>
        <li>Any damage to the product due to unusual weather conditions shall not bind the seller/manufacturer.</li>
        <li>Any replacement of the product beyond the terms and conditions set out herein shall be at our sole discretion and in the goodwill decision of the seller/manufacturer.</li>
        <li>The scope and responsibilities regarding the product during shipping and transit shall be borne by the seller/manufacturer.</li>
        <li>The seller/manufacturer will absolutely determine the quantity numbers for delivery, including free delivery if any.</li>
        <li>Any refund of money as against the return of the product/stone, at the decision of the seller/manufacturer, shall not include the cost of delivery and/or return.</li>
        <li>The buyer shall ensure to check the delivery timeline or lead time for delivery of the product.</li>
        <li>Any delay in delivery due to force majeure or unfortunate circumstances shall in turn delay the delivery. The purchaser shall understand the same.</li>
    </ol>
    </div>
    </div>
  )
}

export default TermsCondition
