// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader.css */

/* Full-screen overlay with blurred background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(216, 216, 216, 0.5); /* Semi-transparent background */
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px); /* Blur effect for the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears on top of other content */
  }
  
  /* Spinner styling */
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  /* Spinner animation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Spinner.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,gDAAgD;AAChD;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,gCAAgC;IACtE,kCAA0B;YAA1B,0BAA0B,EAAE,mCAAmC;IAC/D,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,8CAA8C;EAC/D;;EAEA,oBAAoB;EACpB;IACE,oCAAoC;IACpC,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA,sBAAsB;EACtB;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* Loader.css */\n\n/* Full-screen overlay with blurred background */\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(216, 216, 216, 0.5); /* Semi-transparent background */\n    backdrop-filter: blur(5px); /* Blur effect for the background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999; /* Ensure it appears on top of other content */\n  }\n  \n  /* Spinner styling */\n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #3498db;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n  }\n  \n  /* Spinner animation */\n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
