import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";

const SuccessScreen = () => {
  const location = useLocation();

  const [success, setSuccess] = useState({
    amount: "",
    order_id: "",
    currency: "",
    order_status: "",
  });
  const [refcode, setRefCode] = useState("Pay to view Code");
  const [paymentText, setPaymentText] = useState("");
  useEffect(() => {
    // Update paymentText based on data.status when data changes
    if (success.order_status === "Success") {
      setPaymentText("Payment Success");
    } else if (success.order_status === "Failure") {
      setPaymentText("Payment Failed");
    }
  }, [success.order_status]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encRequest = searchParams.get("encRequest");

    if (encRequest) {
      const decodedString = decodeURIComponent(encRequest);
      const params = new URLSearchParams(decodedString);
      const amount = params.get("amount");
      const order_id = params.get("order_id");
      const currency = params.get("currency");
      const order_status = params.get("order_status");
      if (amount) {
        console.log(params.toString());
        console.log(amount);
        console.log(order_id);
        console.log(currency);
        console.log(order_status);
        setSuccess({
          amount,
          order_id,
          currency,
          order_status,
        });
      }
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/refcode?id=${success.order_id}`
        );
        if (res.data.value.PaymentStatus === "Success") {
          setRefCode(res.data.value.ReferalCode);
          console.log(res.data);
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };
    fetchData();
  }, [success]);
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center vh-100"
      style={{ background: "linear-gradient(to bottom, white, #dadbad)" }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={logo}
          alt="Uploaded"
          style={{ width: "234px", height: "130px" }}
        />
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>

      <div
        className=" bg-white"
        style={{
          boxShadow: "0 0 7px 0 black",
          borderRadius: "5px",
          width: "300px",
        }}
      >
        <div className="d-flex-column" style={{ margin: "10px" }}>
          <h2 className="text-center p-3">{paymentText}</h2>
          <div className="d-flex p-2 ml-5 gap-4">
            <label>Amount :</label>
            <div>{success.amount}</div>
          </div>
          <div className="d-flex p-2 gap-4">
            <label>Order_id :</label>
            <div>{success.order_id}</div>
          </div>
          <div className="d-flex p-2 gap-4">
            <label>Currency :</label>
            <div>{success.currency}</div>
          </div>
          <div className=" d-flex p-2 gap-4">
            <label>Status :</label>
            <div>{success.order_status}</div>
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/" className="w-50">
              <button style={{ background: "#DF5A04" }}>Try Again</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-1">
        <h3>{refcode}</h3>
      </div>
    </div>
  );
};

export default SuccessScreen;
