import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style.css";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Webcam from "react-webcam";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/esm/CloseButton";
import axios from "axios";
import Swal from "sweetalert2";
// import logo from "../Images/VIB Logo.svg";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import TextField from "@mui/material/TextField";
import scanner from "../Images/Scanner.png";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CameraAlt } from "@mui/icons-material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Spinner from "./Spinner";
import banner1 from "../Images/Banner_one.jpg";
import banner2 from "../Images/Banner_two.jpg";
import banner3 from "../Images/Banner_three.jpg";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
//import crypto from 'crypto';
//import { Buffer } from 'buffer';
//const crypto = require('crypto');
//import { Console } from "console";
//import { SHA256, enc } from 'crypto-js';
//const CryptoJS = require('crypto-js');
//const ccav = require('./ccavutil.js');

const SCANNER = `${process.env.REACT_APP_BACKEND_URL}/Scanner.png`;

const Scanner = (props) => {
  const { referalCode } = props;
  const downloadFileAtURL = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((response) => {
        const ValueURL = window.URL.createObjectURL(new Blob([response]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = ValueURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="d-flex justify-content-end mt-3 me-3">
        <CloseButton
          onClick={() => {
            props.onHideScanner();
          }}
        />
      </div>
      <div className="text-center p-3">
        <img
          src={scanner}
          alt="scanner"
          className="img-fluid rounded"
          style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)", width: "80%" }}
        />
      </div>

      <div className="py-4 d-flex flex-column align-items-center gap-4">
        <div className="text-center text-danger text-bold p-2">
          (PLEASE MAKE SURE TO MENTION THE <br></br>
          <b>REFERRAL CODE : {referalCode}</b> DURING THE PAYMENT PROCESS.)
        </div>
        <div className="d-flex justify-content-center gap-3">
          <button
            className="btn btn-success btn-md"
            onClick={() => {
              downloadFileAtURL(SCANNER);
            }}
          >
            <i className="bi bi-cloud-download"></i> Download
          </button>
          <button
            className="btn btn-danger btn-md"
            onClick={props.onHideScanner}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};

// ImageGroup component memoized to prevent re-render
const ImageGroup = React.memo(({ currentIndex, imageGroups }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center mt-1"
      style={{ height: "100px" }}
    >
      {imageGroups[currentIndex]} {/* Display the current image group */}
    </div>
  );
});

const Camera = (props) => {
  const webcamRef = useRef(null);

  const { imageCaptured, Image, handleImageTake, handleRetake, handleSubmit } =
    props;

  const Take = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleImageTake(imageSrc);
  }, [webcamRef]);

  return (
    <div className="">
      <Modal {...props} backdrop="static" keyboard={false}>
        <div className="d-flex justify-content-end">
          <CloseButton onClick={props.onHide} />
        </div>

        {!imageCaptured ? (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              maxScreenshotHeight={180}
              style={{ transform: "scaleX(-1)" }}
            />
            <div className="d-flex justify-content-between m-3">
              <button className="btn-primary btn" onClick={Take}>
                <CameraAlt />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="botter text-center">
              <img
                src={Image}
                alt="userprofile"
                style={{ transform: "scaleX(-1)" }}
              />
            </div>
            <div className="d-flex justify-content-between m-3 gap-3">
              <button className="btn btn-secondary" onClick={handleRetake}>
                Retake
              </button>
              <button className="btn btn-success" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

const CustomerRegistrationPage = () => {

  const backendUrl = process.env.REACT_APP_BACKEND_URL

  const [loading, setLoading] = useState(false);

  const [isCameraImage, setIsCameraImage] = useState(false);
  const [Show, setShow] = useState(false);
  const [Image, setImage] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCloseCamera = () => setShow(false);

  const handleShowCamera = () => {
    setShow(true);
  };

  const handleImageTake = (imageSrc) => {
    setImage(imageSrc);
    setImageCaptured(true);
  };
  const handleRetake = () => {
    setImage(null);
    setImageCaptured(false);
  };

  const handleSubmit = () => {
    // Your submit logic here
    setIsCameraImage(true);
    setShow(false);
  };

  // const [isHovered, setIsHovered] = useState(false);

  // useEffect(() => {
  //   // Dynamically import Bootstrap JavaScript
  //   import("bootstrap/dist/js/bootstrap.bundle.min.js")
  //     .then((bootstrap) => {
  //       const tooltipTriggerList = document.querySelectorAll(
  //         '[data-bs-toggle="tooltip"]'
  //       );
  //       tooltipTriggerList.forEach((tooltipTriggerEl) => {
  //         const tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
  //           html: true, // Allows HTML content in the tooltip
  //         });

  //         // Set dynamic HTML content for the tooltip
  //         tooltip.setContent({
  //           ".tooltip-inner": `
  //          <div>
  //            <span>Package contains</span>
  //            <span>5 interlock bricks</span>
  //            <span>includes all taxes</span>
  //            <div className='d-flex flex-column'>
  //            <span>Size:</span>
  //            <span>12 x 8 x 5</span>
  //            </div>
  //          </div>
  //        `,
  //         });
  //       });
  //     })
  //     .catch((err) => console.error("Bootstrap JS loading error: ", err));

  //   const toggleHover = () => {
  //     setIsHovered((prev) => !prev);
  //   };

  //   // Set interval to toggle hover every 3 seconds
  //   const intervalId = setInterval(toggleHover, 1000);

  //   // Cleanup interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  const [anchorElement, setanchorElementement] = React.useState(null);
  const open = Boolean(anchorElement);
  const handleClick = (event) => {
    setanchorElementement(event.currentTarget);
  };
  const handleClose = () => {
    setanchorElementement(null);
  };

  const openFileSelector = () => {
    document.getElementById("fileInput").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type && file.type.includes("image")) {
        const reader = new FileReader();
        reader.onload = async () => {
          const image = document.createElement("img");
          image.src = reader.result;

          image.onload = () => {
            const maxWidth = 300; // Set your desired maximum width
            const maxHeight = 300; // Set your desired maximum height

            let newWidth = image.width;
            let newHeight = image.height;

            // Check and resize if necessary
            if (image.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (image.height * maxWidth) / image.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (image.width * maxHeight) / image.height;
            }

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            // Set canvas dimensions
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw image on canvas with resized dimensions
            context.drawImage(image, 0, 0, newWidth, newHeight);

            // Convert canvas to base64 string
            const compressedBase64 = canvas.toDataURL("image/jpeg", 0.9); // Adjust quality if needed

            // Store the compressed base64 string in a variable
            setImage(compressedBase64);
            setIsCameraImage(false);
          };
        };
        reader.readAsDataURL(file);
      } else {
        console.error("Selected file is not an image");
      }
    }
  };

  const [ScreenWidth, setWidth] = useState(window.innerWidth);
  // console.log(ScreenWidth);
  const [modalShow, setModalShow] = React.useState(false);
  const [referalCode, setReferalCode] = useState("");

  const [inputValue, setInputValue] = useState({
    CustomerName: "",
    PhoneNumber: "",
    IdProof: "",
    idNumber: "",
    SelectDistrict: "",
    Type: "",
    EmployeeCode: "",
    LandProperty: false,
    BuildHouse: false,
    QuantityType: "savingBrick",
  });

  const tamil_nadu_districts = [
    "Ariyalur/அரியலூர்",
    "Chengalpattu/செங்கல்பட்டு",
    "Chennai/சென்னை",
    "Coimbatore/கோயம்புத்தூர்",
    "Cuddalore/கடலூர்",
    "Dharmapuri/தர்மபுரி",
    "Dindigul/திண்டுக்கல்",
    "Erode/ஈரோடு",
    "Kallakurichi/கள்ளக்குறிச்சி",
    "Kanchipuram/காஞ்சிபுரம்",
    "Kanyakumari/கன்னியாகுமரி",
    "Karur/கரூர்",
    "Krishnagiri/கிருஷ்ணகிரி",
    "Madurai/மதுரை",
    "Nagapattinam/நாகப்பட்டினம்",
    "Namakkal/நாமக்கல்",
    "Nilgiris/நீலகிரி",
    "Perambalur/பெரம்பலூர்",
    "Pudukkottai/புதுக்கோட்டை",
    "Ramanathapuram/ராமநாதபுரம்",
    "Ranipet/ராணிப்பேட்டை",
    "Salem/சேலம்",
    "Sivaganga/சிவகங்கை",
    "Tenkasi/தென்காசி",
    "Thanjavur/தஞ்சாவூர்",
    "Theni/தேனி",
    "Thoothukudi/தூத்துக்குடி",
    "Tiruchirappalli/திருச்சிராப்பள்ளி",
    "Tirunelveli/திருநெல்வேலி",
    "Tirupathur/திருப்பத்தூர்",
    "Tiruppur/திருப்பூர்",
    "Tiruvallur/திருவள்ளூர்",
    "Tiruvannamalai/திருவண்ணாமலை",
    "Tiruvarur/திருவாரூர்",
    "Vellore/வேலூர்",
    "Viluppuram/விழுப்புரம்",
    "Virudhunagar/விருதுநகர்",
  ];

  const totalAmount = 499;

  const setValue = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const idLabels = {
    Aadhaar: "Enter Aadhaar Number/ஆதார் எண்",
    PAN: "Enter PAN Number/பான் எண்",
    DrivingLicense: "Driving License Number/ஓட்டுநர் உரிமம் எண்",
  };

  const setCheckValue = (e) => {
    const { name, checked } = e.target;
    setInputValue((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [error, setErrorMessage] = useState({});

  const SubmitRegisterForm = async (e) => {
    e.preventDefault();
    console.log(inputValue);
    const Error = {};
    if (!Image) {
      Error.Image = "Image is Required!";
    }
    if (!inputValue.CustomerName) {
      Error.CustomerName = "Name is Required!";
    }
    if (!inputValue.PhoneNumber) {
      Error.PhoneNumber = "PhoneNumber is Required!";
    } else if (inputValue.PhoneNumber.length !== 10) {
      Error.PhoneNumber = "Give a Correct PhoneNumber!";
    }
    if (!inputValue.SelectDistrict) {
      Error.SelectDistrict = "District is Required!";
    }
    if (!inputValue.Type) {
      Error.Type = "Application type is Required!";
    }
    if (inputValue.Type === "Employee") {
      if (!inputValue.EmployeeCode) {
        Error.EmployeeCode = "Employee code is Required!";
      } else if (inputValue.EmployeeCode.length !== 10) {
        Error.EmployeeCode = "Give a Correct EmployeeCode!";
      }
    } else if (inputValue.Type !== "Employee") {
      inputValue.EmployeeCode = null;
    }
    if (!inputValue.QuantityType) {
      Error.QuantityType = "Bricks Type is required!";
    }
    if (!termsAccepted) {
      Error.terms = "You must accept the terms and conditions";
    }

    setErrorMessage(Error);

    console.log(Error);

    if (Object.keys(Error).length === 0) {
      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/Register`,
          { inputValue, Image },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W",
            },
          }
        );
        console.log("Data:", response.data);
        console.log("Status:", response.status);

        if (response.status === 200) {
          setReferalCode(response.data.referalCode);
          const refcode = response.data.referalCode;
          console.log("entered", refcode);

          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/triggerId?refcode=${refcode}`
          );
          console.log(res.data);
          console.log("ID = ", res.data.value.id);
          const id = res.data.value.id;
          const order_id = id;
          const tid = 1000 + order_id;

          const requestData = {
            tid: tid,
            merchant_id: "3314106",
            order_id: order_id,
            currency: "INR",
            redirect_url: `${backendUrl}/success`,
            cancel_url: `${backendUrl}/Cancel`,
            language: "EN",
          };
          const response1 = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/encrypt`,
            { requestData }
          );
          console.log("ResponseFromServer:", response1);
          if (response1.status === 200) {
            const { url, encRequest, accessCode } = response1.data;

            // Create a form element dynamically
            const form = document.createElement("form");
            form.action = url; // CCAvenue URL
            form.method = "POST";

            // Create hidden input for access code
            const accessCodeInput = document.createElement("input");
            accessCodeInput.type = "hidden";
            accessCodeInput.name = "access_code";
            accessCodeInput.value = accessCode;
            form.appendChild(accessCodeInput);

            // Create hidden input for encrypted request
            const encRequestInput = document.createElement("input");
            encRequestInput.type = "hidden";
            encRequestInput.name = "encRequest";
            encRequestInput.value = encRequest;
            form.appendChild(encRequestInput);

            // Append form to body and submit
            document.body.appendChild(form);
            form.submit(); // Automatically submit the form to CCAvenue
            //   window.location.href = response.data.url;
          } else {
            console.log("Response Failed:", response1);
          }

          setInputValue({
            CustomerName: "",
            PhoneNumber: "",
            IdProof: "",
            idNumber: "",
            SelectDistrict: "",
            Type: "",
            EmployeeCode: "",
            LandProperty: false,
            BuildHouse: false,
            QuantityType: "savingBrick",
          });
          setImage(null);
          setLoading(false);
        } else if (response.status === 422) {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: "Try Again Later!",
          });
        } else if (response.status === 500) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Register not success,Try again !",
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        Swal.fire({
          icon: "info",
          title: "Bad Gate Way!",
        });
      }
    }
  };

  const imageGroups = useMemo(
    () => [
      <div key="group1">
        <img src={banner1} alt="Brick Banner-1" style={{ width: "350px" }} />
      </div>,
      <div key="group2">
        <img src={banner2} alt="Brick Banner-2" style={{ width: "350px" }} />
      </div>,
      <div key="group3">
        <img src={banner3} alt="Brick Banner-3" style={{ width: "350px" }} />
      </div>,
    ],
    []
  );

  // useEffect for automatic image sliding
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageGroups.length); // Loop through the images
    }, 5000); // 5 seconds interval

    return () => clearInterval(interval); // Clean up on component unmount
  }, [imageGroups.length]);

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, white, #dadbad)",
        maxHeight: "2000px",
      }}
    >
      <div className="container py-2">
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={logo}
            alt="Uploaded"
            style={{ width: "234px", height: "130px" }}
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center gap-2"
          style={{ height: "50px" }}
        >
          <img
            src={GSTN_logo}
            alt="GST"
            style={{ width: "40px", height: "40px" }}
          />
          <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
            GSTIN : 33AKTPV6692C1ZJ
          </p>
        </div>

        {/* Secure Images */}
        <div
          className="d-flex justify-content-center align-items-center mt-1"
          style={{ height: "100px" }}
        >
          <ImageGroup currentIndex={currentIndex} imageGroups={imageGroups} />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="shadow bg-white mb-2"
            style={{
              maxWidth: "350px",
              border: "1px solid #3f2a05",
              borderRadius: "5px",
            }}
          >
            <form
              className="row px-4 py-2 text-left"
              onSubmit={SubmitRegisterForm}
            >
              <div className="d-flex flex-column align-items-center justify-content-center my-2">
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Upload Photo">
                      <Box
                        onClick={handleClick}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        sx={{
                          width: 120,
                          height: 120,
                          borderRadius: "50%",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        {Image ? (
                          <Avatar
                            src={Image}
                            alt="Selected"
                            sx={{ width: 120, height: 120 }}
                            style={
                              isCameraImage ? { transform: "scaleX(-1)" } : {}
                            }
                          />
                        ) : (
                          <CameraAltIcon sx={{ fontSize: 50 }} />
                        )}
                      </Box>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*,.jpeg,.jpg,.png,.gif,.bmp,.svg,.webp,.ico"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorElement}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "center",
                    }}
                    anchorOrigin={{ horizontal: "center", vertical: "center" }}
                    style={{
                      marginLeft: 50, // Half of the avatar width
                      marginTop: 50, // Half of the avatar height
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        openFileSelector();
                        handleClose();
                      }}
                    >
                      Choose from Gallery
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleShowCamera();
                        handleClose();
                      }}
                    >
                      Take photo
                    </MenuItem>
                  </Menu>
                </React.Fragment>
                <p>Upload Photo/புகைப்படம்</p>
                {error.Image && (
                  <span style={{ color: "#df5a04" }}>
                    <b>{error.Image}</b>
                  </span>
                )}
              </div>

              <div className="py-2">
                <TextField
                  className="w-100"
                  onChange={setValue}
                  value={inputValue.CustomerName}
                  id="CustomerName"
                  name="CustomerName"
                  label="Name/பெயர்"
                />
              </div>
              {error.CustomerName && (
                <span style={{ color: "#df5a04" }}>
                  <b>{error.CustomerName}</b>
                </span>
              )}
              <div className="py-2">
                <TextField
                  className="w-100"
                  onChange={setValue}
                  value={inputValue.PhoneNumber}
                  id="PhoneNumber"
                  name="PhoneNumber"
                  label="Phone Number/தொலைபேசி எண்"
                />
                {error.PhoneNumber && (
                  <span style={{ color: "#df5a04" }}>
                    <b>{error.PhoneNumber}</b>
                  </span>
                )}
              </div>
              <div className="py-2">
                <TextField
                  select
                  className="w-100"
                  onChange={setValue}
                  name="SelectDistrict"
                  id="SelectDistrict"
                  label="District/மாவட்டம்"
                  value={inputValue.SelectDistrict}
                >
                  <MenuItem value="" selected disabled>
                    Select District
                  </MenuItem>
                  {tamil_nadu_districts.map((district) => {
                    const isVisible =
                      district.includes("Karur/கரூர்") ||
                      district.includes("Coimbatore/கோயம்புத்தூர்");
                    return (
                      <MenuItem
                        key={district}
                        value={district}
                        style={{
                          filter: isVisible ? "none" : "blur(1.5px)", // Blur all districts except Karur and Coimbatore
                          pointerEvents: isVisible ? "auto" : "none", // Disable selection for blurred items
                        }}
                      >
                        {district}
                      </MenuItem>
                    );
                  })}
                </TextField>

                {error.SelectDistrict && (
                  <span style={{ color: "#df5a04" }}>
                    <b>{error.SelectDistrict}</b>
                  </span>
                )}
              </div>

              <div className="py-2">
                <TextField
                  select
                  className="w-100"
                  onChange={setValue}
                  id="Type"
                  name="Type"
                  label={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "5px",
                          lineHeight: "4px",
                          width: "250px",
                        }}
                      >
                        <p style={{ fontSize: "14px" }}>
                          How do you know about us
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          எங்களை பற்றி எப்படித் தெரியும்
                        </p>
                      </div>
                    </>
                  }
                  value={inputValue.Type}
                >
                  <MenuItem value="Self">Self</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </TextField>
                {error.Type && (
                  <span style={{ color: "#df5a04" }}>
                    <b>{error.Type}</b>
                  </span>
                )}
              </div>

              {inputValue.Type === "Employee" && (
                <div className="py-2">
                  <TextField
                    className="w-100"
                    onChange={setValue}
                    value={inputValue.EmployeeCode}
                    id="EmployeeCode"
                    name="EmployeeCode"
                    label="Employee Number"
                  />
                  {error.EmployeeCode && (
                    <span style={{ color: "#df5a04" }}>
                      <b>{error.EmployeeCode}</b>
                    </span>
                  )}
                </div>
              )}

              {/* <div className="py-2" style={{ margin: "10px 0" }}>
                <div className="center-box d-flex align-items-center">
                  <div className="animated-border-box-glow"></div>
                  <div className="animated-border-box">
                    <p>Saving Interlock Bricks &#8377;499</p>
                    <p
                      className="ms-2"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title=" "
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-info-circle"></i>
                    </p>
                  </div>
                </div>
                {error.QuantityType && (
                  <span style={{ color: "#df5a04" }}>
                    <b>{error.QuantityType}</b>
                  </span>
                )}
              </div> */}

              <div className="py-1 mt-2">
                <div
                  style={{
                    display: "flex",
                    justifyContent:'center',
                    border: "1px solid #e15801",
                    borderRadius: "5px",
                    boxShadow: '0 0  17px 0 #ffbb90',
                    padding:'8px 1px ',
                    gap:'3px'
                  }}
                >
                  <div style={{fontSize:'14px'}}>
                    <span>Total Amount / </span>
                    <span>மொத்த தொகை </span>
                  </div>
                  <b
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      fontSize:'15px'
                    }}
                  >: &#8377; {totalAmount}
                  </b>
                </div>
              </div>

              <div className="d-flex" style={{margin:'10px 0'}}>
                <span style={{ fontSize: "13px" }}>
                  Purchase Details :5 (12± x 8 x 5) interlock bricks including
                  GST and Handling charges
                </span>
              </div>

              {/* <FormControlLabel
                control={
                  <Checkbox
                    id="BuildHouse"
                    name="BuildHouse"
                    checked={inputValue.BuildHouse}
                    onChange={setCheckValue}
                  />
                }
                label={
                  <>
                    <div style={{ fontSize: "13px", color: "black" }}>
                      Consider building your house with us?
                    </div>
                    <div style={{ fontSize: "13px", color: "black" }}>
                      எங்களுடன் சேர்ந்து உங்கள் வீட்டைக் கட்டலாமா?
                    </div>
                  </>
                }
                className="mt-3"
              /> */}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                  />
                }
                label={
                  <>
                    <div style={{ fontSize: "15px" }}>
                      I accept all the{" "}
                      <Link
                        to="/terms&condition"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        terms and conditions
                      </Link>
                    </div>
                  </>
                }
              />
              {error.terms && (
                <span style={{ color: "#df5a04" }}>
                  <b>{error.terms}</b>
                </span>
              )}
              <div className="d-flex flex-column gap-4 mt-2">
                <button className="auto-shiny-button">Buy Now</button>
              </div>

              <div className="mt-1">
                <span style={{ fontSize: "14px", margin: "5px" }}>
                  To order more number of bricks -
                  <a href="tel:9894148456" style={{ textDecoration: "none" }}>
                    {" "}
                    98941 48456
                  </a>
                </span>
              </div>
            </form>

            <Camera
              show={Show}
              onHide={() => {
                setShow(false);
              }}
              handleRetake={handleRetake}
              handleSubmit={handleSubmit}
              handleImageTake={handleImageTake}
              imageCaptured={imageCaptured}
              Image={Image}
            />
            <Scanner
              show={modalShow}
              onHideScanner={() => setModalShow(false)}
              referalCode={referalCode}
            />
            {loading && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRegistrationPage;
